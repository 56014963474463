import { Options } from '../../data/ExternalTransmission'
import { useExternalTransmission } from '../../hooks/useExternalTransmission'
import { TablePC } from './TablePC'
import { TableSP } from './TableSP'

/**
 * 外部送信ポリシーのコンテンツ部分コンポーネント
 */
export const ExternalTransmission = () => {
  const { companyName, serviceName, filteredTableList, handleSelectChange } =
    useExternalTransmission()

  return (
    <div className="pb-12">
      <div>
        <select
          className="w-full border border-border10 py-3 pr-1 pl-4 mb-6"
          onChange={handleSelectChange}
        >
          <option value="">対象サービス一覧</option>
          {Options.map((group, i) => (
            <optgroup key={i} label={group.company}>
              {group.siteNames.map((option, j) => (
                <option key={j} value={option}>
                  {option}
                </option>
              ))}
            </optgroup>
          ))}
        </select>
        <p className="mb-3 text-lg font-bold leading-tight">{companyName}</p>
        <p className="mb-6 text-md font-bold leading-tight">{serviceName}</p>

        {filteredTableList.length ? (
          <TablePC data={filteredTableList} />
        ) : (
          <p>該当するサービスがありません</p>
        )}

        {filteredTableList.length ? (
          filteredTableList.map((row, i) => <TableSP key={i} row={row} />)
        ) : (
          <p>該当するサービスがありません</p>
        )}
      </div>
    </div>
  )
}
