import React from 'react'
import { LayoutNoSideNav } from '../components/organisms/Layout/LayoutNoSideNav'
import { SEO } from '../components/data/SEO'
import { ENGLISH_TITLE } from '../components/data/English'
import { ExternalTransmission } from '../components/organisms/ExternalTransmission/ExternalTransmission'
import { HeadingTertiary } from '../components/atoms/Heading/HeadingTertiary'

const Page = () => {
  return (
    <LayoutNoSideNav
      heading="外部送信ポリシー"
      headingEnglish={ENGLISH_TITLE.externalTransmission}
      BreadcrumbData={[{ title: '外部送信ポリシー', url: '/' }]}
    >
      <div className="pc:pt-12 pc:px-8  bg-white">
        <div className="pc:pt-12">
          <HeadingTertiary label="利用者情報の外部送信について" />
          <p className="mt-5 text-md leading-normal mb-2">
            CRGグループ各社が運営する各種ウェブサイトでは、サービス向上及びお客様に適したサービスを提供するために、外部事業者が提供するサービスを利用しており、Cookie等の端末識別子を利用してお客様に関する情報（利用者情報）を当該外部事業者に送信しています。外部事業者の名称・サービス名、外部事業者に送信される利用者情報の内容、送信される情報の利用目的については、以下より詳細をご確認ください。
          </p>
          <div className="mb-12"></div>
        </div>
        <ExternalTransmission />
      </div>
    </LayoutNoSideNav>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => <SEO title="外部送信ポリシー" url="/" />
