import { LinkBase } from '../../atoms/Link/LinkBase'
import { ExternalTransmissionRow } from '../../data/ExternalTransmission'
import React, { useState } from 'react'
import { colorList } from '../../styles/color'
import { css } from '@emotion/react'

type Props = {
  row: ExternalTransmissionRow
}
const stylePlusMinus = css`
  position: relative;
  &::before,
  &::after {
    background-color: ${colorList.primary};
    content: '';
    position: absolute;
  }
  &::before {
    //horizon
    height: 1px;
    right: 14px;
    bottom: 51%;
    width: 12px;
  }
  &::after {
    //vertical
    height: 12px;
    left: -21px;
    bottom: 43%;
    transition: transform 0.3s ease-out;
    width: 1px;
  }
  &:hover {
    &::before,
    &::after {
      background-color: ${colorList.primary};
    }
    span {
      color: white;
    }
  }
  &.isOpen {
    span {
      color: white;
    }
    ::before,
    ::after {
      background-color: ${colorList.primary};
    }
    ::after {
      transform: rotate(90deg);
    }
  }
`

export const TableRow = ({ row }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClick = () => {
    setIsOpen(!isOpen)
    // console.log(isOpen)
  }

  return (
    <>
      <tr className="flex justify-between leading-tight border-l-[1px] border-border10 w-full pla text-xs align-top h-full">
        <td
          className="w-[118px] px-4 py-5 border border-border10 grid items-center border-t-0 border-l-0"
          dangerouslySetInnerHTML={{
            __html: `${row.category.join('<br/>')}`,
          }}
        ></td>
        <td
          className="w-[154px]  text-center px-4 py-5 border border-border10 grid place-items-center border-t-0 border-l-0"
          dangerouslySetInnerHTML={{
            __html: `${row.providerName.join('<br/>')}`,
          }}
        ></td>
        <td
          className="w-[160px] text-center px-4 py-5 border border-border10 grid place-items-center border-t-0 border-l-0"
          dangerouslySetInnerHTML={{
            __html: `${row.serviceName.join('<br/>')}`,
          }}
        ></td>
        <td className="w-[180px] text-center px-4 py-5 border border-border10 grid place-items-center border-t-0 border-l-0">
          <LinkBase href={row.privacyPolicy}>
            <span className="inline-flex items-center">
              <span className="pr-1">プライバシーポリシー</span>
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.5 1.00001C6.5 0.723861 6.72386 0.5 7.00001 0.5H11.5001C11.7762 0.5 12.0001 0.723861 12.0001 1.00001V5.50007C12.0001 5.77621 11.7762 6.00007 11.5001 6.00007C11.2239 6.00007 11.0001 5.77621 11.0001 5.50007V1.50001H7.00001C6.72386 1.50001 6.5 1.27615 6.5 1.00001Z"
                  fill="#CC050B"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 3.00001C0 2.72386 0.223861 2.5 0.500007 2.5H4.50006C4.77621 2.5 5.00007 2.72386 5.00007 3.00001C5.00007 3.27615 4.77621 3.50001 4.50006 3.50001H1.00001V11.5001H9.00012V8.00007C9.00012 7.72393 9.22398 7.50007 9.50013 7.50007C9.77627 7.50007 10.0001 7.72393 10.0001 8.00007V12.0001C10.0001 12.2763 9.77627 12.5001 9.50013 12.5001H0.500007C0.223861 12.5001 0 12.2763 0 12.0001V3.00001Z"
                  fill="#CC050B"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.3536 1.14645C11.5489 1.34171 11.5489 1.6583 11.3536 1.85357L5.85357 7.35364C5.6583 7.5489 5.34171 7.5489 5.14645 7.35364C4.95118 7.15838 4.95118 6.84179 5.14645 6.64652L10.6465 1.14645C10.8418 0.951184 11.1584 0.951184 11.3536 1.14645Z"
                  fill="#CC050B"
                />
              </svg>
            </span>
          </LinkBase>
        </td>
        <td
          className="hover:text-primary cursor-pointer w-[122px] indent-[-14px] text-center grid place-items-center px-4 py-5 border border-l-0 border-border10 border-t-0"
          onClick={handleClick}
        >
          外部送信情報
        </td>
        <dt
          css={css`
            ${stylePlusMinus}
            &::after {
              //vertical
              right: 14px;
            }
          `}
          className={
            `relative z-10 cursor-pointer duration-default ease-in-out hover:bg-primary hover:text-white ` +
            (isOpen ? ' isOpen bg-primary text-white' : '')
          }
        ></dt>
      </tr>
      {isOpen && (
        <tr className="pc:block">
          <td className={`duration-default ease-out bg-bg10 p-6 w-[763px]`}>
            <div className="bg-bg10block items-start">
              <h5 className="text-xs font-bold pb-1">利用目的</h5>
              <p
                className="text-xs leading-normal"
                dangerouslySetInnerHTML={{
                  __html: `${row.purposeOfUse.join('<br/>')}`,
                }}
              />
              <h5 className="text-xs font-bold pb-1 mt-5">送信情報例</h5>
              <p
                className="text-xs leading-normal"
                dangerouslySetInnerHTML={{
                  __html: `${row.example.join('<br/>')}`,
                }}
              />
            </div>
          </td>
        </tr>
      )}
    </>
  )
}
