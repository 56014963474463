import { useState } from 'react'
import { Options, TableList } from '../data/ExternalTransmission'

/**
 * 外部送信ポリシーの絞り込み機能フック
 */
export const useExternalTransmission = () => {
  // 選択された値を管理するステート変数
  const [selectedValue, setSelectedValue] = useState('')

  /**
   * セレクト変更関数
   */
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(event.target.value)
  }

  const filteredTableList = TableList.filter((row) => {
    // 初期値の場合は全て返却
    if (!selectedValue) {
      return true
    }
    return row.siteNames.includes(selectedValue)
  })

  const selectedCompany = Options.find((option) =>
    option.siteNames.includes(selectedValue)
  )

  return {
    companyName: selectedCompany?.company || '',
    serviceName: selectedValue,
    filteredTableList,
    handleSelectChange,
  }
}
