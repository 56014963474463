import { ExternalTransmissionRow } from '../../data/ExternalTransmission'
import { TableRow } from './TableRow'

type Props = {
  data: ExternalTransmissionRow[]
}

export const TablePC = ({ data }: Props) => {
  return (
    <table className="hidden pc:block pb-14 border-collapse relative">
      <thead className="bg-bg10 border-collapse">
        <tr className="flex w-full items-center text-sm">
          <th className="w-[118px] px-4 py-5 border border-border10 ">分類</th>
          <th className="w-[154px] px-4 py-5 border border-border10 ">
            事業者名
          </th>
          <th className="w-[160px] px-4 py-5 border border-border10 ">
            サービス名
          </th>
          <th className="w-[180px] px-4 py-5 border border-border10 ">
            プライバシーポリシー
          </th>
          <th className="w-[122px] px-4 py-5 border border-border10 ">
            外部送信情報
          </th>
        </tr>
      </thead>
      <tbody className="border-collapse">
        {data.map((row, i) => (
          <TableRow key={i} row={row} />
        ))}
      </tbody>
    </table>
  )
}
